import React, { useEffect, useState, useMemo } from "react";
import { useRouteMatch } from "react-router";

import { Lightbox, Button } from ".";
import { useStore } from "../store";
import {
  generateURL,
  getCollectionFromData,
  useKeyPress,
  svgReportURL,
} from "../utils";

import "./Collection.scss";

export const CollectionThumbnail = ({
  section,
  path,
  name,
  files,
  className = "",
}) => {
  const uniqueFileTypes = [...new Set(files.map((f) => `${f.type}s`))];
  const match = useRouteMatch();
  const setLightbox = useStore((store) => store.actions.setLightbox);

  return (
    <div className={`collection__container ${className}`}>
      <div
        className="pointer"
        onClick={() => {
          setLightbox({
            article: match.params.slug,
            section,
            collection: name,
            isOpen: true,
          });
        }}
      >
        <div className="flex flex-column">
          <div>{name}</div>
          <div className="h4 italic">
            {files.length} item{files.length > 1 && `s`}
          </div>
          <div className="my2">
            {files.length && files[0].type === "image" && (
              <img
                className="collection__thumbnail"
                src={`${generateURL(`${files[0].path}`)}`}
                loading="lazy"
                alt=""
                width="100%"
                style={{ maxHeight: `300px` }}
              />
            )}
            {files.length && files[0].type === "document" && (
              <div className="flex justify-center">
                <img
                  className="collection__thumbnail"
                  src={svgReportURL}
                  loading="lazy"
                  alt=""
                  width="100%"
                  style={{ maxHeight: `300px` }}
                />
              </div>
            )}
            <div className="flex justify-center h5">
              {Array(files.length)
                .fill(`◦`)
                .map((f) => f)}
            </div>
          </div>
          <div className="italic h4 left-align">
            {uniqueFileTypes.join("/")}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CollectionView = ({ article, section, collection }) => {
  const data = useStore((store) => store.collections);
  const setLightbox = useStore((store) => store.actions.setLightbox);
  const arrowLeft = useKeyPress("ArrowLeft");
  const arrowRight = useKeyPress("ArrowRight");

  const [selectedIdx, setSelectedIdx] = useState(0);

  const { files } = getCollectionFromData({
    data,
    article,
    section,
    collection,
  });

  const decrement = useMemo(
    () => () =>
      setSelectedIdx((selectedIdx) =>
        selectedIdx - 1 < 0 ? files.length - 1 : selectedIdx - 1
      ),
    [files.length]
  );

  const increment = useMemo(
    () => () =>
      setSelectedIdx((selectedIdx) => (selectedIdx + 1) % files.length),
    [files.length]
  );

  useEffect(() => {
    arrowLeft && decrement();
    arrowRight && increment();
  }, [
    arrowLeft,
    arrowRight,
    setSelectedIdx,
    increment,
    decrement,
    files.length,
  ]);

  return (
    <Lightbox
      onClose={() => {
        setLightbox({ isOpen: false });
      }}
    >
      <div
        className="flex flex-column justify-between"
        style={{ maxHeight: `95vh` }}
      >
        <div
          className="flex-auto p1 border-box self-center align-center"
          style={{ height: `calc(100vh - 200px)`, width: `100%` }}
        >
          <div className="flex flex-row justify-between items-center h-100">
            <div className="h1 pointer" onClick={decrement}>
              ←
            </div>
            <div
              className="flex flex-column justify-around center mx1"
              style={{ height: `60vh` }}
            >
              {files[selectedIdx].type === "image" && (
                <img
                  className="border-box"
                  style={{ maxHeight: `60vh`, maxWidth: `100%` }}
                  src={generateURL(`${files[selectedIdx].path}`)}
                  alt={files[selectedIdx].name}
                />
              )}
              {files[selectedIdx].type === "document" && (
                <div style={{ maxWidth: `40ch` }}>
                  <div>{files[selectedIdx].name}</div>
                  <Button className="my2">
                    <a
                      href={generateURL(`${files[selectedIdx].path}`)}
                      target="__blank"
                    >
                      Read the document
                    </a>
                  </Button>
                </div>
                // <img
                //   className="border-box"
                //   style={{ maxHeight: `60vh`, maxWidth: `100%` }}
                //   src={generateURL(`${imagePrefix}/${files[selectedIdx].name}`)}
                //   alt={files[selectedIdx].name}
                // />
              )}
            </div>

            <div className="h1 pointer" onClick={increment}>
              →
            </div>
          </div>
        </div>
        <div className="flex flex-column col-12 pb2 items-center h3">
          <div
            dangerouslySetInnerHTML={{ __html: files[selectedIdx].caption }}
          />
        </div>
        <div className="col-12 border-top" />
        <div
          className="flex flex-row justify-around p2 border-box m-auto"
          style={{ maxWidth: `100%` }}
        >
          {files.map((f, idx) => {
            const imageURL =
              f.type === `image` ? `${generateURL(`${f.path}`)}` : svgReportURL;
            return (
              <div
                className="pointer mx1"
                key={`${collection}-${f.name}`}
                onClick={() => setSelectedIdx(idx)}
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                  backgroundImage: `url(${imageURL})`,
                  height: `100px`,
                  width: `100px`,
                  transition: `opacity .2s ease-in`,
                  opacity: idx === selectedIdx ? 1 : 0.4,
                }}
              />
            );
          })}
        </div>
      </div>
    </Lightbox>
  );
};
