import create from "zustand";
import { devtools } from "zustand/middleware";

export const fetchData = async (filename) => {
  return import("./data/posts.json").then((d) => d.default);
  // return [];
};

export const fetchCollections = async () => {
  return import("./data/collections.json").then((d) => d.default);
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case `AUTH_SUCCESSFUL`:
      return {
        app: {
          ...state.app,
          isAuthenticated: true,
        },
      };
    default:
      break;
  }
};

const initialState = {
  app: {
    isAuthenticated: false,
    lightbox: { isOpen: false },
  },
  data: [],
  collections: [],
};

export const useStore = create(
  devtools((set, get) => ({
    ...initialState,
    initData: async () => {
      const data = await fetchData();
      const collections = await fetchCollections();
      set((state) => ({
        data,
        collections,
      }));
    },
    actions: {
      setLightbox({ article, section, collection, isOpen }) {
        const { app } = get();
        set((state) => ({
          ...state,
          app: { ...app, lightbox: { article, section, collection, isOpen } },
        }));
      },
    },
    dispatch: (args) => set((state) => reducer(state, args)),
  }))
);
