import React, { useEffect, useRef, useState, useCallback } from "react";
import Player from "@vimeo/player";

import "./Collection.scss";
import { formatSecondsToMins } from "../utils";

const VideoPlayer = React.forwardRef(
  (
    {
      url,
      onLoadMetadata = () => {},
      options = {},
      style = {},
      className = "",
    },
    ref
  ) => {
    const localRef = useRef();
    const videoContainerRef = ref || localRef;
    const player = useRef();
    // const metadataCallback = useCallback(() => onLoadMetadata, [onLoadMetadata]);

    useEffect(() => {
      if (!videoContainerRef.current) return;
      player.current = new Player(videoContainerRef.current, {
        url,
        ...options,
        responsive: true,
      });
    }, [videoContainerRef, player, url, options]);

    useEffect(() => {
      if (!player.current) return;
      Promise.all([
        player.current.getVideoTitle(),
        player.current.getDuration(),
      ]).then(([title, duration]) => {
        // metadataCallback()({ title, duration });
        onLoadMetadata({ title, duration });
      });
    }, [player, onLoadMetadata]);

    return <div className={className} style={style} ref={videoContainerRef} />;
  }
);

const VideoThumbnail = ({ url }) => {
  const [metadata, setMetadata] = useState(null);
  const onLoadMetadataCb = useCallback((m) => setMetadata(m), []);

  return (
    <div className="flex flex-column border mr2 my2 p2 collection__container">
      {
        <div className={`mb2 ${metadata && `fade-in`}`}>
          {metadata && (
            <div>
              {metadata.title} ({formatSecondsToMins(metadata.duration)})
            </div>
          )}
        </div>
      }
      <div>
        <VideoPlayer url={url} onLoadMetadata={onLoadMetadataCb} />
      </div>
    </div>
  );
};

export { VideoPlayer, VideoThumbnail };
