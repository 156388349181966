import React, { useEffect, useRef } from "react";

export const SVGCallback = ({
  callbacks = [{ target: "", callback: () => {} }],
  src = ``,
  selected = ``,
  Component = null,
  alt = "",
  className = "",
}) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!svgRef.current) return;
    // if (eventsAttached.current) return
    callbacks.forEach((c) => {
      const targets = svgRef.current.querySelectorAll(c.target);
      targets.forEach((t) => {
        t.classList.remove("selected");
      });
    });
    if (selected) {
      const targets = svgRef.current.querySelectorAll(selected);
      targets.length &&
        targets.forEach((s) => {
          s.classList.add("selected");
        });
    }

    // eventsAttached.current = false;
  }, [svgRef, callbacks, selected]);
  return (
    <Component ref={svgRef} className={`border__container ${className}`} />
  );
  // return <img className="svg__callback" src={src} alt={alt} ref={svgRef} />;
};
