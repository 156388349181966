import React from "react";

import "./Button.scss";

export const Button = ({ children, className }) => {
  return (
    <div className={`button__container p1 pointer ${className}`}>
      {children}
    </div>
  );
};
