import React from "react";
import { useAuth } from "../providers/auth-provider";
import { Form } from "../components";

export function Login() {
  const { login } = useAuth();

  const inputs = [
    {
      name: "password",
      type: "password",
      placeholder: "Enter password",
    },
  ];

  return (
    <div
      className="flex flex-column justify-center fixed top-0 bottom-0 left-0 right-0 items-center"
      // style={{ width: `100vw`, height: `100vh`, overflow: `hidden` }}
    >
      <div className="">
        <Form title="Login" onSubmit={login} inputs={inputs} />
      </div>
    </div>
  );
}
