import React, { useState } from "react";
import PropTypes from "prop-types";

import "./Form.scss";

function Form(props) {
  const [input, setInput] = useState({});

  const onSubmit = async (e) => {
    e.preventDefault();

    await props.onSubmit(input);
  };

  return (
    <div className="form__container p3 col-12">
      <form onSubmit={onSubmit}>
        {/* <fieldset disabled={loading} aria-busy={loading}> */}
        {props.inputs.map((i) => (
          <label key={i.name}>
            {/* {i.name} */}
            <input
              className="form__input"
              value={input[i.name] || ""}
              type={i.type}
              placeholder={i.placeholder || ""}
              onChange={(e) => {
                const value = e.target.value;
                setInput((prev) => ({ ...prev, [i.name]: value }));
              }}
            />
          </label>
        ))}
        <input type="submit" value="→" />
        {/* </fieldset> */}
      </form>
    </div>
  );
}

Form.propTypes = {
  title: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape(
      {
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
      }.isRequired
    )
  ),
  onSubmit: PropTypes.func.isRequired,
};

export { Form };
