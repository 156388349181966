import React from "react";
import { Route, Switch } from "react-router-dom";

import { Landing } from "./pages";
import { Article, Header } from "./components";

import { urls } from "./utils";

function App({ data: posts }) {
  return (
    <div className="my1 flex flex-column  m-auto" id="app__container">
      <Switch>
        <Route
          exact
          path={[urls.collection, urls.root, urls.post]}
          render={() => {
            return <Header />;
          }}
        />
      </Switch>
      <Switch>
        <Route
          exact
          path={[urls.root, urls.about]}
          render={() => {
            return <Landing posts={posts} />;
          }}
        />
        <Route
          exact
          name="post"
          path={[urls.post]}
          render={() => {
            return <Article />;
          }}
        />
        {/* <Route
          exact
          path={[urls.collection]}
          render={() => {
            return <CollectionView />;
          }}
        /> */}
      </Switch>
      {/* <div className="border__container">
        <img id="border__img" src={svgMeric} alt="" />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="col-12">
          {posts.map(({ title }, idx) => (
            <Post
              index={formatRomanNumeral(idx + 1)}
              title={title}
              key={`post-list-${idx}`}
            />
          ))}
        </div>
      </div> */}
    </div>
  );
}

export default App;
