import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

import { About, Posts, SVGCallback } from "../components";
import { ReactComponent as SVGBorder } from "../assets/img/borders.svg";
import { urls } from "../utils";

export function Landing({ posts }) {
  const [hovered, setHovered] = useState(null);

  return (
    <div className="landing__container">
      <SVGCallback
        Component={SVGBorder}
        callbacks={posts.map((p) => ({ target: `.${p.slug}` }))}
        selected={hovered}
        className="fixed top-0 left-0"
      />
      <div className="px3">
        <Switch>
          <Route exact path={urls.root}>
            <Posts
              posts={posts}
              onPostOver={(e) => {
                setHovered(e);
              }}
            />
          </Route>
          <Route exact path={urls.about}>
            <About />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
