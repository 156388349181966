export const urls = {
  about: "/about",
  collection: "/:slug/:section/collection/:collectionName",
  post: "/:slug",
  root: "/",
};

// S3 replaces spaces with +
export const generateURL = (url) =>
  // encodeS3URI(
  //   `${process.env.REACT_APP_ASSETS_ROOT_FOLDER}/${url.replace(/ /g, "+")}`
  // );
  `${process.env.REACT_APP_ASSETS_ROOT_FOLDER}/${encodeS3URI(url)}`;
// encodeURIComponent(
//   encodeURIComponent(`${process.env.REACT_APP_ASSETS_ROOT_FOLDER}/${url}`)
// );
// `${process.env.REACT_APP_ASSETS_ROOT_FOLDER}/${url.replace(/ /g, "+")}`;

export function encodeS3URI(filename) {
  const encodings = {
    "+": "%2B",
    "!": "%21",
    '"': "%22",
    "#": "%23",
    $: "%24",
    "&": "%26",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "*": "%2A",
    ",": "%2C",
    ":": "%3A",
    ";": "%3B",
    "=": "%3D",
    "?": "%3F",
    "@": "%40",
  };

  return encodeURI(filename) // Do the standard url encoding
    .replace(/(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|:|;|=|\?|@)/gim, function (match) {
      return encodings[match];
    });
}
