import React, { useEffect, useRef } from "react";
import { useRouteMatch } from "react-router";
import { ResizeObserver } from "@juggle/resize-observer";
import useMeasure from "react-use-measure";

import { useStore } from "../store";
import { generateURL } from "../utils";
import {
  Accordion,
  CollectionThumbnail,
  VideoThumbnail,
  VideoPlayer,
  CollectionView,
} from ".";
import "./Article.scss";

const Cover = ({ data = {}, url = "", subtitle = "", title = "" }) => {
  const [videoRef, videoBounds] = useMeasure({ polyfill: ResizeObserver });
  // const videoRef = useRef();
  // const [containerRef, containerBounds] = useMeasure({ polyfill: ResizeObserver });
  const containerRef = useRef();

  useEffect(() => {
    // if (!videoRef.current || !containerRef.current) return;
    // if (!videoRef.current) return;
    if (data.type !== `vimeo`) return;
    // console.log("hello");
    const { height: videoHeight } = videoBounds;
    containerRef.current.style.height = `${videoHeight * 1.05}px`;
  }, [data, videoBounds, containerRef, videoRef]);

  useEffect(() => {
    if (!containerRef.current) return;
    if (data.type === "image") {
      const img = new Image();
      img.onload = function () {
        const { naturalHeight, naturalWidth } = img;
        const aspectRatio = naturalHeight / naturalWidth;
        console.log("aspect ratio", aspectRatio);
        containerRef.current.style.height = `${
          window.innerWidth * aspectRatio
        }px`;
      };
      img.src = url;
    }
  }, [containerRef, data.type, url]);
  return (
    <div
      ref={containerRef}
      className="flex border-box flex-column fade-in article-card__image mb4 h1"
      style={{
        // position: `relative`,
        paddingBottom: `10vh`,
        // height: `50vh`,
        backgroundImage: data.type === "image" && `url(${url})`,
      }}
    >
      {data.type === `vimeo` && (
        <div
          ref={videoRef}
          style={{
            position: `absolute`,
            width: `100vw`,
            opacity: `.4`,
          }}
        >
          <VideoPlayer
            url={data.path}
            className="z0"
            options={{
              background: true,
              loop: true,
              muted: true,
              responsive: true,
            }}
          />
        </div>
      )}
      <div className="z1 flex flex-auto justify-center items-center clearfix px3 h0">
        <div className="flex-col">
          <div className="capitalize h0 block-font">{title}</div>
          <div className="h1">{subtitle}</div>
        </div>
      </div>
    </div>
  );
};

const TextBlock = ({ html }) => {
  return (
    <div
      className="fs-2rem lh-lg ch-70 m-auto h2 lh-md mt2 mb4 col-12 md-col-9 lg-col-6"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export const Article = ({ splashImage = "header_photo.jpg" }) => {
  // const location = useLocation();
  const match = useRouteMatch();
  // const posts = useStore((state) => state.data);
  // const post = posts.find((post) => post.slug === match.params.slug);
  const collections = useStore((state) => state.collections);
  const lightbox = useStore((store) => store.app.lightbox);
  const { setLightbox } = useStore((state) => state.actions);

  const post = collections.find((post) => post.name === match.params.slug);

  useEffect(() => {
    return function cleanup() {
      setLightbox({ isOpen: false });
    };
  }, [setLightbox]);

  return (
    <>
      <div className="h2 content-start">
        <Cover
          data={post.metadata.cover}
          url={generateURL(`${post.slug}/${post.metadata.cover.path}`)}
          title={post.title}
          subtitle={post.subtitle}
        />
        <div className="flex flex-column items-center px3">
          {/* <div className="h1">{post.intro || `intro text goes here`}</div> */}
          <TextBlock html={post.metadata.text} />
          {/* <div
            className="fs-2rem lh-lg ch-70 m-auto h2 lh-md mt2 mb4"
            dangerouslySetInnerHTML={{ __html: post.metadata.text }}
          /> */}
        </div>
        {lightbox.isOpen && (
          <CollectionView
            article={lightbox.article}
            section={lightbox.section}
            collection={lightbox.collection}
          />
        )}
        {post.sections.map((section) => (
          <div className="my4 px3 m-auto" style={{ maxWidth: `1440px` }}>
            <div className="flex flex-column items-center">
              <div className="flex flex-column my4 block-font items-center ">
                <h2 className="">{section.name}</h2>
                {section?.metadata?.subtitle && (
                  <div className="">{section.metadata.subtitle}</div>
                )}
              </div>
              <TextBlock html={section?.metadata?.text} />
              {/* <div
                className="sm-col-12 ch-70 fs-1rem h2 lh-md mb4 mt2"
                dangerouslySetInnerHTML={{ __html: section?.metadata?.text }}
              /> */}
            </div>
            {/* <div className="sm-col-12 md-col-6">{section?.metadata?.text}</div> */}
            {/* {section.metadata?.videos && (
              <div className="flex flex-row flex-wrap col-12 h3 mt3">
              </div>
            )} */}
            <div className="flex flex-row flex-wrap col-12 h3 mt3">
              {section.metadata?.videos?.length > 0 && (
                <div className="my2 col-12">
                  <Accordion isOpen={true} title={"Videos"}>
                    {section.metadata?.videos.map((video, idx) => (
                      <div className="my2 sm-col-12 md-col-4">
                        <VideoThumbnail
                          key={`video-thumbnail-${section.name}-${idx}`}
                          url={video.url}
                        />
                      </div>
                    ))}
                  </Accordion>
                </div>
              )}
              {section.collections?.length > 0 && (
                <div className="my2 col-12">
                  <Accordion isOpen={true} title={"Additional Media"}>
                    {section.collections.map((collection, i) => (
                      <CollectionThumbnail
                        className={`lg-col-3 md-col-4 mr2 sm-col-12 p2 my3 border-white border`}
                        path={collection.path}
                        name={collection.name}
                        files={collection.files}
                        section={section.name}
                      />
                    ))}
                  </Accordion>
                </div>
              )}
              {section.metadata?.attachments.length > 0 && (
                <div className="my4 col-12">
                  <Accordion isOpen={true} title={"Attachments"}>
                    {section.metadata.attachments.map(
                      (attachment, i) =>
                        attachment.type === "embed" && (
                          <div
                            className="mt3 col-12"
                            dangerouslySetInnerHTML={{
                              __html: attachment.url.replace(/"$/g, ""),
                            }}
                          />
                        )
                    )}
                  </Accordion>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
