import React, { createContext, useContext, useState } from "react";

async function sendRequest(endpoint, body, successCallback) {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
  };

  if (body) {
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(body);
  }

  const response = await fetch(
    `/.netlify/functions/${endpoint}`,
    requestOptions
  );

  if (response.ok) {
    const responseBody = await response.json();
    successCallback(responseBody);
  }
}

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const localUserJson = localStorage.getItem("user");
  const localUser = localUserJson && JSON.parse(localUserJson);
  const [user, setUser] = useState(localUser);
  const [isAuthenticated, setIsAuthenticated] = useState(
    process.env.NODE_ENV === "development"
      ? true
      : !!localStorage.getItem("isAuthenticated")
  );

  const authenticateUser = (user) => {
    setUser(user);
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", true);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const login = (password) => sendRequest("auth", password, authenticateUser);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, login }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
