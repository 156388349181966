import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Login } from "./pages";
import { useAuth, AuthProvider } from "./providers/auth-provider";
// import "basscss/css/basscss.min.css";

import "./index.scss";
import App from "./App";
import { useStore } from "./store";
import reportWebVitals from "./reportWebVitals";

export const history = createBrowserHistory();

const DataProvider = ({ render }) => {
  const initData = useStore(useCallback((state) => state.initData, []));
  const data = useStore((state) => state.data);

  useEffect(() => {
    console.info("initializing data...");
    initData();
  }, [initData]);

  return data.length ? render(data) : null;
};

const RouteAuthentication = ({ render }) => {
  const { isAuthenticated } = useAuth();

  return render(isAuthenticated);
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <RouteAuthentication
        render={(isAuthenticated) =>
          isAuthenticated ? (
            <Router history={history}>
              <DataProvider render={(data) => <App data={data} />} />
            </Router>
          ) : (
            <Login />
          )
        }
      />
      {/* <Router history={history}>
        <DataProvider
          render={(data) => {
            const { isAuthenticated } = useAuth();
            console.log(isAuthenticated);
            return <App data={data} />;
          }}
        />
      </Router> */}
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
