import React, { useEffect } from "react";
import "./Lightbox.scss";

import { useKeyPress } from "../utils";

export const Lightbox = ({ children, onClose = () => {} }) => {
  const escPress = useKeyPress("Escape");

  useEffect(() => {
    escPress && onClose();
  }, [escPress, onClose]);

  return (
    <div
      className="lightbox__container flex flex-column justify-between fixed z2 top-0 left-0 right-0 bottom-0 p2 border-box"
      style={{ maxHeight: `100vh` }}
    >
      <div className="flex flex-column justify-start mx4">
        <div className="pointer h2" onClick={onClose}>
          X
        </div>
        <div className="flex flex-column">{children}</div>
      </div>
    </div>
  );
};
