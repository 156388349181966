// TODO this should be made recursive
export const getCollectionFromData = ({
  data = [],
  article,
  section,
  collection,
}) => {
  const { sections } = data.find((c) => c.slug === article);
  const { collections } = sections.find((s) => s.name === section);
  const { files } = collections.find((c) => c.name === collection);

  const imagePrefix = `${article}/${section}/${collection}`;

  return {
    imagePrefix,
    files,
  };
};
