import React, { useState } from "react";

export const Accordion = ({
  children,
  isOpen = true,
  title,
  className = "",
}) => {
  const [open, setOpen] = useState(isOpen);

  return (
    <div className={`flex flex-column flex-wrap ${className}`}>
      <div
        onClick={() => setOpen((open) => !open)}
        className="flex flex-row h2 col-12 pointer"
      >
        <div className="mx2">{!open ? `▶` : `▼`}</div>
        <div>{title}</div>
      </div>
      {open && (
        <div className="fade-in flex flex-row flex-wrap col-12">{children}</div>
      )}
    </div>
  );
};

// ("▼▲▶");
