import React from "react";
import { Link } from "react-router-dom";

import { formatRomanNumeral } from "../utils";
import "./Post.scss";

export const Post = ({ index, title, subtitle = `` }) => {
  return (
    <div className="post__container flex flex-row items-start content-start my4">
      <div className="flex flex-column">
        <div className="post__title block-font h1">{title}</div>
        <div className="post__title mb1 h2">{subtitle}</div>
      </div>
    </div>
  );
};

export const Posts = ({
  posts,
  onPostOver = () => {},
  onPostExit = () => {},
}) => {
  return (
    <div className="flex flex-row flex-wrap my4">
      <div className="m-auto">
        {posts.map(({ title, slug, subtitle }, idx) => (
          <Link
            onMouseOver={() => onPostOver(`.${slug}`)}
            onMouseOut={() => onPostOver(null)}
            to={`/${slug}`}
            key={`post-link-${idx}`}
          >
            <Post
              index={formatRomanNumeral(idx + 1)}
              title={title}
              subtitle={subtitle}
              key={`post-list-${idx}`}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
