import React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

import { urls } from "../utils";

export const Header = () => {
  // const location = useLocation();
  const match = useRouteMatch();
  console.log(match);

  return (
    <div className="header__container flex flex-row flex-wrap z2 h2 px3 pt2 pb2 fixed left-0 right-0 top-0">
      <div className="fade-in col-12 xs-hide sm-col-1 h1 left-align">
        {match.path !== urls.root && match.url !== urls.about && (
          <Link className="hover-bold" to={urls.root}>
            ←
          </Link>
        )}
      </div>

      <div className="flex-column col-7 pb1">
        <Link to={urls.root}>
          <div className="bold">Border Natures</div>
          <div className="h3">
            The Environment as Weapon at the Edges of Greece
          </div>
        </Link>
      </div>
      <div className="col-3 h3 pointer">
        {match.url === urls.about ? (
          <Link className="h0 hover-bold myn2" to={urls.root}>
            x
          </Link>
        ) : (
          <Link className="hover-bold" to={urls.about}>
            About
          </Link>
        )}
      </div>
      {/* <div className="col-12 sm-col-2">Map</div> */}
    </div>
  );
};
