import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export const About = () => {
  useEffect(() => {
    // change variables
    let root = document.documentElement;
    const colorA = getComputedStyle(root).getPropertyValue("--blackVal");
    const colorB = getComputedStyle(root).getPropertyValue("--whiteVal");

    root.style.setProperty("--blackVal", colorB);
    root.style.setProperty("--whiteVal", colorA);

    return function cleanup() {
      // change back color variables
      root.style.setProperty("--blackVal", colorA);
      root.style.setProperty("--whiteVal", colorB);
    };
  }, []);

  return (
    <div className="about__container h2 m-auto col-12 md-col-9 lg-col-6 ch-70 lh-lg fs-2rem py4 fade-in">
      <p>
        <span class="block-font">Border Natures</span> is a PhD research project
        by Stefanos Levidis, carried out within the Centre for Research
        Architecture, Visual Cultures, Goldsmiths University.
      </p>
      <p>
        The work critically investigates the complex imbrications of political
        violence and environmental processes resulting in the construction of
        so-called “natural” borders: national boundaries where nature is
        understood to delimit and fortify the nation-state.{" "}
        <p>
          Contrary to conceptions of natural borders as spaces that operate
          devoid of human intervention, or lines that are simply scripted over
          natural backdrops, the project demonstrates how their materiality and
          natural processes are in fact hybridized and engineered by states to
          perform the specific, deadly tasks of deterrence, exclusion and
          erasure.
        </p>
        <p>
          <span class="block-font">Border Natures</span> is here introduced as a
          term that critically encompasses these hybrid boundary environments,
          and the complex causal relationships they engender. It narrates the
          border as a synthesis of nature, space, technology and law, and
          connecting actors as diverse as border authorities, fences,
          technologies of surveillance, political and legal orders, human and
          more-than-human forms of life - rivers, waves, and mountains.
        </p>
      </p>
      <p>
        The research is split in three separate, but interconnected sections,
        investigating different case studies along the Greek borders.{" "}
        <p>
          <Link to={"/ghost-habitats"}>
            <span className="block-font">Part I, Ghost Habitats</span>
          </Link>
          , explores the mountainous, trilateral border area of the
          Prespa/Prespa/Prespës lakes between Greece, North Macedonia, and
          Albania, to consider how the delineation and enforcement of the border
          reconfigures regional ecosystems to render them into “wild” frontiers,
          that have historically been subject to different legal orders than the
          remainder of sovereign territory.
        </p>
        <p>
          <Link to={"/anachoma"}>
            <span className="block-font">Part II, Anáchoma</span>
          </Link>
          , (co-researched with Ifor Duncan, Post-doctoral fellow at Ca’ Foscari
          University, Venice) considers the Evros/Meriç/Maritsa river between
          Greece, Turkey and Bulgaria as an apparatus of border violence and
          obfuscation, as well as a body which records, stores, and occasionally
          reveals this violence through seasonal shifts in its hydrological
          cycle.
        </p>
        <p>
          <Link to={"/grey-rocks-black-waves"}>
            <span className="block-font">
              Part III, Grey Rocks, Black Waves
            </span>
          </Link>
          , examines the archipelagic border of the Aegean Sea between Greece
          and Turkey, to demonstrate how its geographically and historically
          dense materiality is mobilized as a defensive infrastructure against
          contemporary migration at sea. In each case sovereign power organises,
          and is organised around, specific ecological processes to disperse the
          causal agencies of violence enacted against disempowered border
          subjects, and aspiring crossers.
        </p>
      </p>
      <p>
        Incorporating a methodology that borrows from disciplines as diverse as
        forensics, filmmaking, archaeology, botany, and oceanography, the
        critical spatial practice presented here decodes border materialities to
        reveal obscure, yet persisting, traces of state violence at the edge of
        the nation-state.
      </p>
    </div>
  );
};
